/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed } from 'vue'
import uiStore from '~/src/UserInterface/App/store/UiStore'

export default function useUI() {
  const store = uiStore()

  const showOrganisationOffCanvas = computed({
    get: () => store.showOrganisationOffCanvas,
    set: (newValue) => {
      if ((newValue && store.isHidden) || (!newValue && store.isVisible)) {
        store.toggleOrganisationOffCanvas()
      }
    },
  })

  return {
    showOrganisationOffCanvas,
    hideOrganisationOffCanvas: store.hideOrganisationOffCanvas,
    toggleOrganisationOffCanvas: store.toggleOrganisationOffCanvas,
  }
}
