<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type User from '~/src/Domain/Identity/User'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import type UiOffcanvas from '~/src/UserInterface/App/components/Ui/Offcanvas/Offcanvas.vue'
import useAdminNavigation from '~/src/UserInterface/App/composables/Admin/useAdminNavigation'
import useUI from '~/src/UserInterface/App/composables/useUi'
import useOrganisationNavigation from '~/src/UserInterface/Organisation/composables/useOrganisationNavigation'

const props = defineProps<{
  user: User
  organisation: Organisation
  engagements: Engagement[]
  currentEngagement?: Engagement
  currentPhase?: Phase
}>()

const { route, adminDashboardLocalePath } = useAdminNavigation()
const { organisationSettingsGeneralLocalePath, organisationSettingsEngagementsLocalePath }
  = useOrganisationNavigation(computed(() => props.organisation))

const displayedEngagements = ref(props.engagements)
const search = ref('')
watch(search, (newValue) => {
  displayedEngagements.value
    = newValue === ''
      ? props.engagements
      : props.engagements.filter((e) => e.name.toLowerCase().includes(newValue.toLowerCase()))
})
watch(
  () => props.engagements,
  (newValue) => {
    if (search.value === '') {
      displayedEngagements.value = newValue
    }
  },
)

const offCanvas = ref<InstanceType<typeof UiOffcanvas>>()
const { showOrganisationOffCanvas, hideOrganisationOffCanvas } = useUI()

watch([route, () => props.currentEngagement, () => props.currentPhase], () => hideOrganisationOffCanvas())
</script>

<template>
  <ui-offcanvas
    id="organisation-offcanvas"
    ref="offCanvas"
    v-model="showOrganisationOffCanvas"
    class="bg-white"
    placement="top"
    :hide-backdrop="false"
  >
    <template #header="{ hide }">
      <organisation-logo
        :name="organisation.name"
        :logo="organisation.logo"
      />
      <h3
        v-if="currentEngagement && currentPhase"
        class="mb-0 ms-4 me-4"
      >
        {{ currentEngagement.name }}
      </h3>
      <span v-else-if="organisation">
        {{ organisation.name }}
      </span>

      <layout-engagement-settings-nav-button
        v-if="currentEngagement"
        :organisation
        :engagement="currentEngagement"
      >
        <ui-icon icon="gear-fill" />
        {{ $t('components.organisation.navbar_dropdown.btn_engagement_settings') }}
      </layout-engagement-settings-nav-button>
      <ui-button-close
        class="pe-lg-0 ms-auto me-0 fs-1"
        @click="hide"
      />
    </template>

    <div class="ms-4">
      <form>
        <div class="input-group input-group-flush input-group-merge input-group-reverse">
          <input
            v-model="search"
            type="search"
            class="form-control list-search"
            :placeholder="$t('components.cards.header.lbl_search')"
          >
          <div class="input-group-text">
            <ui-icon icon="search" />
          </div>
        </div>
      </form>
    </div>

    <div>
      <table class="table table-sm table-responsive table-borderless mb-0">
        <thead>
          <tr class="border-bottom">
            <th scope="col">
              {{ $t('components.organisation.navbar_dropdown.tbl_active_engagements') }}
            </th>
            <th
              scope="col"
              class="text-end"
            >
              {{ $t('components.organisation.navbar_dropdown.tbl_sign_off_date') }}
            </th>
            <th
              scope="col"
              class="text-center"
            >
              {{ $t('components.organisation.navbar_dropdown.tbl_navigation') }}
            </th>
            <th
              scope="col"
              class="text-center"
            >
              {{ $t('general.common.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="engagement in displayedEngagements"
            :key="engagement['@id'].toString()"
            v-memo="[engagement.name, engagement['@id'].toString() !== currentEngagement?.['@id'].toString()]"
          >
            <td class="align-middle">
              {{ engagement.name }}
            </td>
            <td class="text-end align-middle">
              {{ $d(engagement.signOffDate.getTime()) }}
            </td>
            <td class="text-center align-middle">
              <ui-button-group>
                <engagement-switch-engagement-button
                  v-for="phase in engagement
                    .phases
                    .filter(p => p.hasAnalysisTypes())"
                  :key="phase['@id'].toString()"
                  :organisation
                  :engagement
                  :phase
                />
              </ui-button-group>
            </td>
            <td class="text-center align-middle">
              <layout-engagement-settings-nav-button
                :organisation
                :engagement
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <template #footer>
      <div class="d-flex align-items-center p-3 border-top border-bottom">
        <ui-img
          :src="organisation.logo"
          height="25"
          class="rounded-1"
        />
        <span class="ms-3 me-3">
          {{ organisation.name }}
        </span>
        <ui-button
          variant="white"
          size="sm"
          class="me-3"
          :to="organisationSettingsGeneralLocalePath"
        >
          <ui-icon icon="gear-fill" />
          {{ $t('components.organisation.navbar_dropdown.btn_organisation_settings') }}
        </ui-button>
        <ui-button
          variant="white"
          size="sm"
          class="me-3"
          :to="organisationSettingsEngagementsLocalePath"
        >
          {{ $t('components.organisation.navbar_dropdown.btn_all_engagements') }}
        </ui-button>
        <div class="ms-auto">
          <ui-button
            v-if="user.isAdmin()"
            variant="white"
            size="sm"
            :to="adminDashboardLocalePath"
          >
            <ui-icon
              icon="sliders"
              style="display: inline-block; transform: rotate(90deg)"
            />
            {{ $t('components.organisation.navbar_dropdown.btn_admin') }}
          </ui-button>
        </div>
      </div>
    </template>
  </ui-offcanvas>
</template>

<style lang="scss">
#organisation-offcanvas {
  bottom: initial;
  height: auto;

  .offcanvas-body {
    padding: 0;
  }
}
</style>
