<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useEngagementNavigation from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'
import usePhaseTypeTranslation from '~/src/UserInterface/Engagement/composables/usePhaseTypeTranslation'
import useWorkProgramNavigation from '~/src/UserInterface/WorkProgram/composables/useWorkProgramNavigation'

const props = defineProps<{
  organisation: Organisation
  engagement: Engagement
  phase: Phase
}>()

const { route, getEngagementLocalePath, engagementDashboardLocalePath } = useEngagementNavigation(
  computed(() => props.organisation),
  computed(() => props.engagement),
  computed(() => props.phase),
)
const { workProgramOverviewLocalePath } = useWorkProgramNavigation(
  computed(() => props.organisation),
  computed(() => props.engagement),
  computed(() => props.phase),
)

const switchPath = ref('/')
watch(
  [
    () => route,
    engagementDashboardLocalePath,
    workProgramOverviewLocalePath,
  ],
  ([newRoute, newEngagementDashboardPath, newWorkProgramOverviewPath]) => {
    const routeName = newRoute.name?.toString().split('___')[0]
    if ('workProgramSlug' in newRoute.params) {
      switchPath.value = newWorkProgramOverviewPath
    } else if (routeName !== undefined && 'phaseId' in newRoute.params) {
      switchPath.value = getEngagementLocalePath(routeName)
    } else {
      switchPath.value = newEngagementDashboardPath
    }
  },
  {
    deep: true,
    immediate: true,
  },
)
</script>

<template>
  <ui-button
    variant="white"
    size="sm"
    :to="switchPath"
  >
    <ui-icon icon="box-arrow-in-right" />
    {{ usePhaseTypeTranslation(phase.type) }}
  </ui-button>
</template>
