/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

const uiStore = defineStore('ui', () => {
  const showOrganisationOffCanvas = ref(false)

  const isVisible = computed(() => showOrganisationOffCanvas.value)
  const isHidden = computed(() => !showOrganisationOffCanvas.value)

  const hideOrganisationOffCanvas = () => {
    showOrganisationOffCanvas.value = false
  }

  const toggleOrganisationOffCanvas = () => {
    showOrganisationOffCanvas.value = !showOrganisationOffCanvas.value
  }

  return {
    showOrganisationOffCanvas,
    isVisible,
    isHidden,
    hideOrganisationOffCanvas,
    toggleOrganisationOffCanvas,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(uiStore, import.meta.hot))
}

export default uiStore
